import React , { useEffect } from "react"
import SEO from "../../components/SEO/SEO"

import Layout from "../../components/layout"
import Container from "../../components/container/container"
import Gumshoe from "gumshoejs"

const SpecificationOfTechnicalReport069 = () => {

  useEffect(() => {
    var myyElement = document.getElementById("js-sticky");

    window.addEventListener('scroll', function (evt) {
      var Positionsss =  GetTopLeft ();
      if (Positionsss.toppp  > 95)	{ myyElement.style.position="relative"; myyElement.style.top = "0px"; }
      else							{ myyElement.style.position="fixed";	myyElement.style.top = "90px";}
    });

    function GetOffset (object, offset) {
      if (!object) return;
      offset.x += object.offsetLeft;       offset.y += object.offsetTop;
      GetOffset (object.offsetParent, offset);
    }
    function GetScrolled (object, scrolled) {
      if (!object) return;
      scrolled.x += object.scrollLeft;    scrolled.y += object.scrollTop;
      if (object.tagName.toLowerCase () !== "html") {          GetScrolled (object.parentNode, scrolled);        }
    }
    function GetTopLeft () {
      var offset = {x : 0, y : 0};		GetOffset (myyElement.parentNode, offset);
      var scrolled = {x : 0, y : 0};		GetScrolled (myyElement.parentNode.parentNode, scrolled);
      var posX = offset.x - scrolled.x;	var posY = offset.y - scrolled.y;
      return {lefttt: posX , toppp: posY };
    }

    var gumshoeElement = document.getElementById("js-article-menu");

    if(gumshoeElement !== null) {
      var spy = new Gumshoe('#js-article-menu a', {
      offset: 40, // how far from the top of the page to activate a content are
      });
    }
  })

  return (
  <Layout>
    <SEO
      title="Specification of Technical Report 069"
      desc="The operation and control, The functions of control, CWMP architecture"
      canonical="https://tr069.cloud/articles/specification-of-technical-report-069/"
      pathname="/articles/specification-of-technical-report-069"
    />
    <article className="article">
      <div className="article__bg--usp">
        <div className="article-intro">
          <Container>
            <h1 className="article-intro__title">Specification of Technical Report 069</h1>
          </Container>
        </div>
      </div>
      <Container>
        <div>
        <nav className="article-menu" id="js-article-menu">
          <ol id="js-sticky">
            <li>
              <a href="#the-operation-and-control">The operation and control</a>
            </li>
            <li>
              <a href="#the-functions-of-control">The functions of control</a>
            </li>
            <li>
              <a href="#cwmp-architecture">CWMP architecture</a>
            </li>
            <li className="article-menu__sub">
              <a href="#cwmp-parameters">CWMP parameters</a>
            </li>
            <li className="article-menu__sub">
              <a href="#cwmp-procedures">CWMP procedures</a>
            </li>
            <li className="article-menu__sub">
              <a href="#autodetection-of-cpe">Autodetection of CPE</a>
            </li>
            <li className="article-menu__sub">
              <a href="#connection-setup">Connection setup</a>
            </li>
            <li className="article-menu__sub">
              <a href="#closing-of-the-session">Closing of the session</a>
            </li>
          </ol>
        </nav>
        <div className="article-content">
          <span className="article-content__first-line-bug"></span>
          <div className="fixed-anchor" id="the-operation-and-control">
            <h2>
              The operation and control
            </h2>
            <p>
              The TR-069 specification describes the CPE WAN Management Protocol (CWMP). CWMP defines the principles of interaction between customer devices (CPE) and the Auto-Configuration Server (ACS). According to TR-069, ACS is a network device and application server that automates execution of basic methods of control over customer devices (CPE).
            </p>
            <p>Among basic methods of control should be mentioned:</p>
            <ul>
              <li>
                Automatic configuration and dynamic reconfiguration of services (the services rendered to the customer by its provider, such as Internet access, VoIP, IPTV)
              </li>
              <li>
                Control over CPE software
              </li>
              <li>
                Monitoring of the current status and performance parameters of CPE.
              </li>
              <li>
                Diagnostics
              </li>
            </ul>
            <p>
              The southbound interface provides the implementation of the above listed control functions with respect to CPEs. Nowadays the CPEs managed by TR-069 include Integrated access devices (IAD), PON and associated optical devices, VoIP devices, IPTV set-top boxes, and other home network devices.
            </p>
            <p>
              The northbound interface provides the interaction of ACS with other OSS/BSS systems of provider as part of implementation of unified end-to-end service management processes.
            </p>
          </div>
          <div className="fixed-anchor" id="the-functions-of-control">
            <h2>
              The functions of control
            </h2>
            <p>
              The key and highly demanded CPE management feature is automatic configuration and dynamic reconfiguration of services. The specification defines the possibility of both primary and subsequent configuration of CPE, for example, at the request of customer or in case of changing certain parameters of the service. TR-069 defines the ability to perform configuration operations with respect to one specific CPE or to multiple groups, united by one or several common features, such as the manufacturer of the CPE, model, firmware version, etc. The CPE software control function is responsible for software downloading to device. The protocol defines the mechanisms for identification of the versions of managed software, activation (initiated by ACS or CPE), execution and completion of image files downloading, logging and support desk notification about the downloading performance. In addition to direct CPE configuration management functions, CWMP defines methods for providing access to information that can be used by ACS server for monitoring the status and performance of CPE. CWMP also defines the set of mechanisms that allow CPE separately notify ACS about changes in its functional state. Apart from CPE monitoring capabilities, CWMP also provides diagnostic mechanisms, including list of parameters that may contain diagnostic information and methods for providing of diagnostic information.
            </p>
          </div>
          <div className="fixed-anchor" id="cwmp-architecture">
            <h2>
              CWMP architecture
            </h2>
            <p>
              The architecture of CWMP protocol includes CWMP protocol stack, used for initiation of communication between ACS and CPE, CWMP parameters and CWMP procedures.
            </p>
            <h3 className="visually-hidden">
              CWMP protocol stack
            </h3>
            <p>
              CWMP protocol is implemented as a set of standard and specifically developed protocols.
            </p>
            <h3 className="fixed-anchor" id="cwmp-parameters">
              CWMP parameters
            </h3>
            <p>
              CWMP parameters are represented in a form of data model whose structure is defined by another Broadband Forum document - “TR-106: Data Model Template for TR-069-Enabled Device”. The main purpose of parameters is to provide ACS information about characteristics and status of CPEs, and to manage their configuration. Parameters can be specified as read-only or read-write. The read-only parameters can be used by ACS server for evaluation of specific characteristics of CPE, its current state or for receiving of accumulated statistics. The read-write parameters allow ACS server to change the CPE configuration. In CWMP, all parameters are combined into hierarchical structure. This data structure in CWMP is represented as an object. Each object contains one parameter or set of parameters. Each CPE has only one main object (root), that depending on the type of device will assume the value Device or Internet Gateway Device.  In most cases, the main object includes three elements: Common Objects, Components, Service Objects. Common Objects contains parameters that define the type of CPE.
            </p>
            <p>Common Object branch parameters are used for identification of CPEs on ACS and include:</p>
            <ul>
              <li>
                DeviceInfo
              </li>
              <li>
                ManagementServer
              </li>
              <li>
                GatewayInfo
              </li>
              <li>
                Time
              </li>
              <li>
                Config
              </li>
              <li>
                UserInterface
              </li>
              <li>
                LAN
              </li>
            </ul>
            <p>
              The Components contains parameters that provide various functions of TR-069. Their specification is provided in the separate documents of BroadBand Forum. Service Objects contains objects for each type of services provided by a specific CPE model. Accordingly, for multiservice CPEs, several objects of the corresponding type are defined. For the main types of services, the parameters are formalized and issued in the form of the corresponding BroadBand Forum recommendations. We wrap-up our brief description of parameters with the notice that the CWMP does not regulate the number of parameters supported by a specific implementation of CPE. Each manufacturer can add its own, specific parameters that will realize the control functions over specific type and model of equipment the best way. The CWMP protocol defines only set of basic parameters that allow to realize the functions of unified management of the operator’s heterogeneous network.
            </p>
            <h3 className="fixed-anchor" id="cwmp-procedures">
              CWMP procedures
            </h3>
            <p>
              The TR-069 specification defines the following basic procedures that will be performed in the process of managing CPE:
            </p>
            <ul>
              <li>
                Autodetection of CPE
              </li>
              <li>
                Connection set up and transmission of informational messages
              </li>
              <li>
                File transfer
              </li>
              <li>
                Closing of session and disconnection
              </li>
            </ul>
            <h3 className="fixed-anchor" id="autodetection-of-cpe">
              Autodetection of CPE
            </h3>
            <p>
              The TR-069 specification defines two main variants of primary (or repeated - in case of loss of communication with the current ACS) connection of CPE to ACS (CPE detection):
            </p>
            <ul>
              <li>
                During the initial local configuration (Web GUI, CLI) of the CPE, information about the ACS access parameters can be directly set by the operator.
              </li>
              <li>
                If you have the possibility to use DHCP, the ACS access parameters (ip, code) can be transferred to the CPE by a DHCP server. In this case, at the moment of connection to the network, the CPE will receive not only its own dynamic IP address, but also the address (IP) and the access code to ACS server.
              </li>
            </ul>
            <p>
              In the latter case, Option 43 of DHCP3 protocol is usually used to define the CWMP parameters. The ACS server information is stored on the CPE as the value of parameter ... Management-Server. URL (where ... is the path to the object in accordance with the hierarchy described above). The current version of TR-069 specification assumes that each CPE is bound to only one ACS. Accordingly, if the connection with the ACS server indicated as the value of the parameter ... ManagementServer.URL is lost, the CPE will initiate additional searching of ACS.
            </p>
            <h3 className="fixed-anchor" id="connection-setup">
              Connection setup
            </h3>
            <p>
              As is common for many types of two-way communications, two variants of connection setup are available:
            </p>
            <ul>
              <li>
                Initiated by CPE
              </li>
              <li>
                Initiated by ACS
              </li>
            </ul>
            <p>
              In accordance with TR-069, the CPE may initiate the interaction session with ACS in one of the following cases:
            </p>
            <ul>
              <li>
                Initial detection and configuration of the CPE.
              </li>
              <li>
                Power up or reboot of the CPE
              </li>
              <li>
                As per schedule, in accordance with the predetermined values ​​of the corresponding parameters
              </li>
              <li>
                If a connection request is received from the ACS
              </li>
              <li>
                If the address of ACS is changed
              </li>
              <li>
                When the values ​​of a number of key parameters for which is defined the function of tracking their values are changing
              </li>
              <li>
                In case of abnormal termination of the previous session
              </li>
            </ul>
            <p>
              In case of successful connection to ACS, the CPE will initiate the INFORM request and finish the session setup procedure.
            </p>
            <p>
              The opening of the session initiated by ACS will be executed by sending the appropriate request to required CPE. The special port 7547 is allocated for transmission of such requests. The request is formed as HTTP GET to a specific CPE url. No data is transmitted from ACS to CPE. The received request is analyzed by the CPE for verification of authenticity and, in case of successful completion of verification, a response will be generated and the session initiated with ACS. The TR-069 specification also describes various requirements for limiting the fact and intensity of sessions between CPE and ACS.
            </p>
            <h3 className="fixed-anchor" id="file-transfer">
              File transfer
            </h3>
            <p>
              The number of CPE control functions are associated with the transfer to them different files (for example: firmware images). You can transfer files using Unicast (HTTP / HTTPS, FTP, SFTP, TFTP) and Multicast (FLUTE, DSM-CC). As a prerequisite to initiation the file transfer procedure, the TR-069 specification defines the presence of active session between ACS and CPE.
            </p>
            <p>
              The file transfer can be organized in one of the following ways:
            </p>
            <ul>
              <li>
                CPE can send HTTP GET / PUT within already established connection. When the file is transferred, the CPE can start sending additional messages to ACS, keeping the session going
              </li>
              <li>
                CPE may open the second session for file transfer saving, at the same time, the primary session with ACS
              </li>
              <li>
                The CPE may terminate the current session with ACS and then initiate the separate session for file transfer
              </li>
            </ul>
            <p>
              If the address of ACS and the source address of the file do not match, only last two ways for files transfer will be applicable.
            </p>
            <h3 className="fixed-anchor" id="closing-of-the-session">
              Closing of the session
            </h3>
            <p>
              Specification TR-069 defines the following conditions fulfillment of which will be necessary for session closing:
            </p>
            <ul>
              <li>
                The last request received by CPE from ACS is empty
              </li>
              <li>
                The CPE has no actual interaction tasks (requests, responses) with ACS
              </li>
              <li>
                The CPE received all responses to requests from ACS
              </li>
              <li>
                The CPE sent all responses to ACS requests
              </li>
            </ul>
            <p>
              Closing of connection session is possible only if all above conditions are met simultaneously. Such a solution is aimed at prevention of possible unexpected termination of the session and subsequent data loss or network failure
            </p>
          </div>
        </div>
      </div>
      </Container>
    </article>
  </Layout>
  )
}

export default SpecificationOfTechnicalReport069
